
/**
 * NOTA: Por incompatibilidad de paths de fuentes definidas en GEF con resolve-url-loader,
 * se definen aquí las fuentes y se han comentado en GEF: 
 * gef/generated/_icons.scss
 * gef/variables/_typography.scss
 */

@font-face {
    font-family: 'uoc-sans';
    src:url('#{$font-url}UOCSans-Regular.woff2') format('woff2'),
    url('#{$font-url}UOCSans-Regular.woff') format('woff'),
    url('#{$font-url}UOCSans-Regular.ttf') format('truetype'),
    url('#{$font-url}UOCSans-Regular.svg#svgFontName') format('svg');
    font-weight:400;
}
@font-face {
    font-family: 'uoc-sans';
    src:url('#{$font-url}UOCSans-Italic.woff2') format('woff2'),
    url('#{$font-url}UOCSans-Italic.woff') format('woff'),
    url('#{$font-url}UOCSans-Italic.ttf') format('truetype'),
    url('#{$font-url}UOCSans-Italic.svg#svgFontName') format('svg');
    font-weight:400;
    font-style:italic;
}
@font-face {
    font-family: 'uoc-sans';
    src:url('#{$font-url}UOCSans-Bold.woff2') format('woff2'),
    url('#{$font-url}UOCSans-Bold.woff') format('woff'),
    url('#{$font-url}UOCSans-Bold.ttf') format('truetype'),
    url('#{$font-url}UOCSans-Bold.svg#svgFontName') format('svg');
    font-weight:600;
}
@font-face {
    font-family: 'uoc-serif';
    src:url('#{$font-url}UOCSerif-Regular.woff2') format('woff2'),
    url('#{$font-url}UOCSerif-Regular.woff') format('woff'),
    url('#{$font-url}UOCSerif-Regular.ttf') format('truetype'),
    url('#{$font-url}UOCSerif-Regular.svg#svgFontName') format('svg');
    font-weight:400;
}

@font-face {
	font-family:"icons";
	src:url("#{$iconfont-url}/icons.woff2?5787c5efe49b5ac180bed3d1d2f7de16") format("woff2"),
		url("#{$iconfont-url}/icons.woff?5787c5efe49b5ac180bed3d1d2f7de16") format("woff"),
		url("#{$iconfont-url}/icons.ttf?5787c5efe49b5ac180bed3d1d2f7de16") format("truetype");
	font-weight:normal;
	font-style:normal;
}

// Ruler and variants
$ruler-width:  					$spacer/2;
$ruler-width-thin:  			$border-width;

// Ruler colors
$ruler-color-base:				$grey;
$ruler-color-primary:			$brand-primary;
$ruler-color-secondary:			$brand-secondary;
$ruler-color-alumni:			$brand-alumni;
$ruler-color-x:					$brand-x;
$ruler-color-media:				$brand-media;
$ruler-color-ri:				$brand-ri;
$ruler-color-corporate:			$brand-corporate;
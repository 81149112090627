// Table
$table-border:					$ruler-width;
$table-border-thin:				$ruler-width-thin;
$table-font-size:				1.25rem;
$table-font-size:				1.25rem;
$table-narrow-width:			20%;
$table-border-color:			$brand-primary;

// Table cell
$table-cell-padding:            $spacer !default;
$table-cell-sub-data-padding:	$spacer !default;

.breadcrumb {
  .breadcrumb-item{
    display: inline;

    a{
      text-decoration: none;
    }

    &.active{
      font-weight: bold;
    }

    &:not(:last-child):after{
      content: "";
      border: solid #000078;
      border-width: 0 .0625rem .0625rem 0;
      display: inline-block;
      padding: .1875rem;
      margin: 0 .1875rem;
      vertical-align: middle;
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .breadcrumb-more{
    display: none;
  }
}

@include media-down(sm){
  .breadcrumb{
    .breadcrumb-item{
      display: none;

      &:first-child, &:last-child, &:nth-last-child(2), &.breadcrumb-aux{
        display: inline;
      }
    }
    .breadcrumb-more{
      display: inline;
      &:before{
        content: "\2026";
      }
    }
  }
}

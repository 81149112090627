// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin reset-text {
  font-family: $font-family-base;

  // We deliberately do NOT reset font-size or word-wrap.
  font-style: normal;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start; // stylelint-disable-line declaration-block-no-duplicate-properties
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}

/* Flexbox */

@mixin flexbox($options: "") {
  display: -webkit-box unquote($options);
  display: -moz-box unquote($options);
  display: -ms-flexbox unquote($options);
  display: -webkit-flex unquote($options);
  display: flex unquote($options);
}

@mixin flex($value: 1, $options: "") {
  -moz-flex: $value unquote($options);
  -webkit-flex: $value unquote($options);
  -ms-flex: $value unquote($options);
  flex: $value unquote($options);
}

@mixin flex-direction($value: row, $options: "") {
  -moz-flex-direction: $value unquote($options);
  -webkit-flex-direction: $value unquote($options);
  -ms-flex-direction: $value unquote($options);
  flex-direction: $value unquote($options);
}

@mixin flex-wrap($value: wrap, $options: "") {
  -moz-flex-wrap: $value unquote($options);
  -webkit-flex-wrap: $value unquote($options);
  -ms-flex-wrap: str-replace($value, "nowrap", "none") unquote($options);
  flex-wrap: $value unquote($options);
}

@mixin align-items($value: center, $options: "") {
  -webkit-align-items: $value unquote($options);
  -ms-flex-align: str-replace($value, "flex-") unquote($options);
  align-items: $value unquote($options);
}

@mixin justify-content($value, $options: "") {
  -moz-justify-content: $value unquote($options);
  -webkit-justify-content: $value unquote($options);
  -ms-flex-pack: str-replace($value, "flex-") unquote($options);
  justify-content: $value unquote($options);
}

@mixin order($value: 0, $options: "") {
  -moz-order: $value unquote($options);
  -webkit-order: $value unquote($options);
  -ms-flex-order: $value unquote($options);
  order: $value unquote($options);
}

@mixin align-self($value: center) {
  -webkit-align-self: $value;
  align-self: $value;
}

/* Icons svg */

//  Helper function to replace characters in a string
@function str-replace($string: "", $search: "", $replace: "") {
  $index: str-index($string, $search);

  @return if(
    $index,
    str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      ),
    $string
  );
}

//  Helper function to encode color
@function hexToUrl($color) {
  $newcolor: str-replace($color + unquote(""), "#", "%23");

  @return $newcolor;
}

/* Datepicker */

%triangle-arrow {
  margin-left: -$datepicker__triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $datepicker__triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: "";
    z-index: -1;
    border-width: $datepicker__triangle-size;

    left: -$datepicker__triangle-size;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: $datepicker__background-color;
  }

  &::before {
    top: -4px;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  top: auto;
  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::before {
    top: auto;
    bottom: -4px;
    border-top-color: $datepicker__border-color;
  }
}


// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}
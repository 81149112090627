
.pagination{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    width: auto;
}


.pagination__link{
	font-family: $font-family-sans;
	@include font-size($font-size-h2);
	font-weight: bold;
	color: $pagination-link-color;
	display: block;
	text-decoration: none;
	border-color: $pagination-border-color;
	border-width: $pagination-border-width;
	border-style: solid;
	line-height: $pagination-link-lineheight;
	width: 100%;

	&:hover:not(span){
		color: $pagination-link-color;
		text-decoration: none;
	}
	.is-active > &{
		border-color: $pagination-border-color-active;
		color: $pagination-border-color-active;
        background-color: $pagination-link-hover-bg;
        &:hover:not(span){
            color: $pagination-border-color-active;
        }
	}
}

.pagination__item:last-child{
    position: static;
    float: none;
    min-width: 25%;
    margin-top: 0;
}

.pagination__item{
    .btn{
        padding: 4px 1em;
    }
}


@include media-up(md){

    .pagination__link{
        padding-right: 1.5rem;
    }

    .pagination__item{
        min-width: 8%;
        float: none;
    }
    .pagination__item:last-child{
        position: static;
        min-width: 25%;
        margin-top: 0;
    }
}

@include media-up(lg){

    .pagination__item{
        min-width: 7%;
    }
    .pagination__item:last-child{
        min-width: 17%;
    }
}

@include media-up(xl){

    .pagination__item{
        min-width: 5%;
    }
    .pagination__item:last-child{
        min-width: 15%;
    }
}
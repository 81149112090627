.form-group {
    margin-bottom: 1rem;

    @include font-size($form-font-size);

    label,
    .label {
        font-weight: bold;
        @include font-size($font-size-base);
        @include media-down(sm) {
            @include font-size($font-size-base);
        }
    }
}

label,
.label {
    margin-bottom: .5rem;
    .btn--tooltip{
        margin-left: .25rem;
    }
}

legend.label{
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.5rem;
    @include font-size($font-size-base);
}

.form-item {
    padding: $form-item-padding;
}

.form-item--inline{
    min-width: 150px;
}

textarea.form-item {
    height: 15rem;
    line-height: 1.1;
}

.select--custom{
    display:inline-block;
    margin-bottom: 0;
    &:before{
        bottom: .7rem;
        right: .7rem;
    }
    select.form-item{
        padding-right: 2rem;
        line-height: 1.15;
        font-weight: normal;
        @include font-size($form-font-size);
    }
    .label{
        display: inline-block;
        margin-bottom: 0.5rem;
    }
}


.form-group--inline {
    margin-right: 1rem;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}


.form-radio label, .form-check label{
    margin-right: 0;
    margin-bottom: .2rem;
}

.radiogroup {
    margin-bottom: .5rem;
}

.is-form-error {
    color: $brand-primary;
    position: inherit;
}

.form--inverse {
    .is-form-error .form-item,
    .is-form-error.form-item {
        color: $brand-primary;
        background-color: $validation-bg;
    }

    .is-form-error {
        .invalid-feedback {
            color: $brand-primary;
            font-size: 1rem;
            margin: .5rem 0;
        }
    }
    
}

.h5 {
    legend {
        float: left;
        width: auto;

        + button.btn--infotip {
            margin-top: -3px;
            margin-left: 3px;
        }
    }
}

.form-group {
    .h5 {
        margin-bottom: .5rem;
    }
}

.btnlink.is-form-error {
    background-color: $validation-bg;
}


.react-datepicker__input-container{
    .icon{
        position: absolute;
        right: .6rem;
        top: .6rem;
        pointer-events: none;
    }
}


.form--filters-groups{

    label,
    .label {
        margin-bottom: .25rem;
        font-weight: normal;
        @include font-size($form-font-size);
        @include media-down(sm) {
            @include font-size($form-font-size);
        }
    }

    legend.label{
        margin-bottom: 0.25rem;
        @include font-size($form-font-size);
    }

    .select--custom{
        margin-bottom: 0;
        .label{
            margin-bottom: 0.25rem;
        }
    }
    

    .react-datepicker-wrapper{
        display: block;
    }

    .form-item {
        padding: .6rem .6rem;
        min-height: 38px;
    }

    .btn{
        min-height: 38px;
        padding: 4px 1em;
    }

    .select--custom{
        width: 100%;
        select.form-item{
            padding-right: 2rem;
        }
    }

    .form-group--range{
        .form-input--datepicker{
            display:block;
            margin-bottom: 1rem;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

@include media-up(sm){
    .form--filters-groups{
        .form-group--range{
            .form-input--datepicker{
                margin-bottom: 0;
                display:inline-block;
                margin-right: 2%;
                width: 49%;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}


@include media-up(md) {
    .form--filters-groups{
        .form-group{
        width: 18.5%;
        }
        .form-group--range{
            width: 39.5%;
        }
    }
}

@include media-up(xl) {
    .form--filters-groups{
        .form-group{
            width: 10%;
        }
        .form-group--send{
            width: 5%;
        }
        .form-group--range{
            width: 19%;
        }
    }
}
// Container widths
// Set the container width, and override it for fixed navbars in media queries.

.container {
	@include clearfix();
	max-width: $grid-max-width;
	margin-right: auto;
  	margin-left: auto;
	padding-left:  $grid-container-padding-xs;
  	padding-right: $grid-container-padding-xs;
}


// Row
// Rows contain and clear the floats of your columns.

.row {
	@include clearfix();
  	margin-left:  ($grid-gutter-width / -2);
  	margin-right: ($grid-gutter-width / -2);

	/* TODO this line corrects problem for grids in tablt devices*/
	//@include media-down(md){
  	@include media-down(md){
	  	margin-left:  ($grid-gutter-width / -4);
	  	margin-right: ($grid-gutter-width / -4);
	}
}


// Columns
// Common styles for small and large grid columns

@include make-grid-columns();

.table--borders{
    td {
        border-color: $grey-light;
    }
}

.table--dynamic {

    .table__header,
    .btn--order {
        background: $white;
    }

    tr:not(.gradient) {
        background: transparent;
        @include transition(background 0.15s linear);
    }

    tr th{
        background: $white;
    }

    tr.highlight {
        background: $brand-secondary-alt;
        @include transition(background 0.15s linear);
    }

    tbody tr:last-child{
        td{
            border:0;
        }
    }
    
}


.table--groups{
    td, th{
        padding: $table-cell-padding-groups;
    }
}

.table{
    tr{
        th{
            text-align:left;
        }
    }
    tr.collapsible-row{
        td{
            padding: 0;
            padding: 0;
            border: 0;
            .collapsible-content{
                border-bottom: 1px solid $grey-light;
                padding-left: $spacer;
                padding-right: $spacer;
            }
        }
    }
}

.table-responsive{
    -webkit-overflow-scrolling: touch;
}

.table-sticky {
    .table-sticky-fixed{
        position: fixed;
        z-index:1;
        top: $fixed-table-heading-position-mobile;
        width: 100%;
        left: 0;
        display: none;
        &.is-sticky{
            display:block;
            animation: slide-down .2s ease-out;
        }
    }
}

.table-sticky-container{
    overflow:hidden;
}

.table-sticky-overflow{
    //overflow: hidden;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    > .table{
        position: relative;
    }
}

@include media-up(md) {
    .table-sticky {
        .table-sticky-fixed{
            top: $fixed-table-heading-position;
        }
    }
}

@include media-down(md) {
    .table{
        @include font-size($font-size-s);
    }
}


@keyframes slide-down {
    0% {
      transform: translateY(-5%);
    }
    100% {
      transform: translateY(0%);
    }
}
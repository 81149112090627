// Responsive Web Design Helpers

// Reset visibility helpers
@include responsive-invisibility('.visible-xs');
@include responsive-invisibility('.visible-sm');
@include responsive-invisibility('.visible-md');
@include responsive-invisibility('.visible-lg');
@include responsive-invisibility('.visible-xl');

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}

// Visibility helpers media queries
@include media-down(xs) {
  @include responsive-visibility('.visible-xs');
}
.visible-xs-block {
  @include media-down(xs) {
    display: block !important;
  }
}
.visible-xs-inline {
  @include media-down(xs) {
    display: inline !important;
  }
}
.visible-xs-inline-block {
  @include media-down(xs) {
    display: inline-block !important;
  }
}

@include media-only(sm) {
  @include responsive-visibility('.visible-sm');
}

.visible-sm-block {
  @include media-only(sm) {
    display: block !important;
  }
}
.visible-sm-inline {
  @include media-only(sm) {
    display: inline !important;
  }
}
.visible-sm-inline-block {
  @include media-only(sm) {
    display: inline-block !important;
  }
}

@include media-only(md) {
  @include responsive-visibility('.visible-md');
}
.visible-md-block {
  @include media-only(md) {
    display: block !important;
  }
}
.visible-md-inline {
  @include media-only(md) {
    display: inline !important;
  }
}
.visible-md-inline-block {
  @include media-only(md) {
    display: inline-block !important;
  }
}

@include media-only(lg) {
  @include responsive-visibility('.visible-lg');
}
.visible-lg-block {
  @include media-only(lg) {
    display: block !important;
  }
}
.visible-lg-inline {
  @include media-only(lg) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @include media-only(lg) {
    display: inline-block !important;
  }
}

@include media-up(xl) {
  @include responsive-visibility('.visible-xl');
}
.visible-xl-block {
  @include media-up(xl) {
    display: block !important;
  }
}
.visible-xl-inline {
  @include media-up(xl) {
    display: inline !important;
  }
}
.visible-xl-inline-block {
  @include media-up(xl) {
    display: inline-block !important;
  }
}

// Invisibility helpers
@include media-down(xs) {
  @include responsive-invisibility('.hidden-xs');
}

@include media-only(sm) {
  @include responsive-invisibility('.hidden-sm');
}

@include media-only(md) {
  @include responsive-invisibility('.hidden-md');
}

@include media-only(lg) {
  @include responsive-invisibility('.hidden-lg');
}

@include media-up(xl) {
  @include responsive-invisibility('.hidden-xl');
}
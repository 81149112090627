/* Buttons */


.btn{
    padding: $form-item-padding;
}

.btn--large {
    padding: .35rem .9rem;
    min-height: 2.65rem;
    line-height: 1.2;
    .icon {
        position: relative;
        font-size: $btn-large-icon-size;
        //top: -2px;
        top: 1px;
    }
}

button.close,
.btn--unstyled,
.btn--popover {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;

    &.disabled, &[disabled] {
        color: $grey;
    }
}

.btn--unstyled {
    color: $brand-primary;

    &:hover, &:focus {
        color: $grey-dark;
    }

    &.disabled, &[disabled] {
        color: $grey;
    }
}


.btn--tooltip {
    cursor: default;
    .icon {
        position: relative;
        top: 2px;
        line-height: .7;
    }
}

td{
    .btn--tooltip {
        .icon {
            top: 1px;
        }
    } 
}

.btn--popover {
    white-space: normal;
    text-align: left;
    line-height: 1.2;
    min-height: 1.25rem;
    outline: none;

    &:hover, &:focus {
        color: $grey;
    }
}

.btn.disabled, .btn[disabled] {
    &:hover, &:focus, &.focus {
        background-color: $grey;
        color: $grey-dark !important;
    }

    &.btn--large {
        @include font-size($btn-large-font-size);

        min-height: 2.65rem;
    }
}

.btn--primary.disabled, .btn--primary[disabled] {
    &.saving {
        color: $white !important;
        background-color: $brand-primary;
    }
}

.btnlink--regular {
    font-weight: $font-weight-base;

    //@include font-size($font-size-base - 1);

    &:hover,
    &:focus {
        color: $grey-dark;
        text-decoration: none;
    }

    &.disabled, &[disabled] {
        color: $grey;
    }
}

.btnlink--underline {
    text-decoration: underline;
}


.btnlink--icon{
    .icon{
        line-height: .6;
        position: relative;
        top: 2px;
    }
}

.btnlink--resync{
    opacity: 1;
    transition: opacity .2s ease;
    &.btn--syncing{
        opacity: .5;
    }
}

.btn--resync{
    opacity: 1;
    transition: opacity .2s ease, width .2 ease;
}
// Generated by grunt-webfont



/*@font-face {
	font-family:"icons";
	src:url("../iconfonts/icons.woff2?5787c5efe49b5ac180bed3d1d2f7de16") format("woff2"),
		url("../iconfonts/icons.woff?5787c5efe49b5ac180bed3d1d2f7de16") format("woff"),
		url("../iconfonts/icons.ttf?5787c5efe49b5ac180bed3d1d2f7de16") format("truetype");
	font-weight:normal;
	font-style:normal;
}*/

.icon {
	
		font-family:"icons";
	
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


// Icons


.icon--access:before {
	content:"\f101";
}


.icon--alert-full:before {
	content:"\f102";
}


.icon--alert:before {
	content:"\f103";
}


.icon--arrow-big:before {
	content:"\f104";
}


.icon--arrow-down:before {
	content:"\f105";
}


.icon--arrow-drop-down:before {
	content:"\f106";
}


.icon--arrow-drop-up:before {
	content:"\f107";
}


.icon--arrow-left:before {
	content:"\f108";
}


.icon--arrow-right:before {
	content:"\f109";
}


.icon--arrow-up:before {
	content:"\f10a";
}


.icon--ask-full:before {
	content:"\f10b";
}


.icon--ask:before {
	content:"\f10c";
}


.icon--asterisk:before {
	content:"\f10d";
}


.icon--attachment:before {
	content:"\f10e";
}


.icon--backward:before {
	content:"\f10f";
}


.icon--book:before {
	content:"\f110";
}


.icon--calendar-full:before {
	content:"\f111";
}


.icon--calendar:before {
	content:"\f112";
}


.icon--campus:before {
	content:"\f113";
}


.icon--checkbox-form:before {
	content:"\f114";
}


.icon--checkbox-off:before {
	content:"\f115";
}


.icon--checkbox-on:before {
	content:"\f116";
}


.icon--checkmark-double:before {
	content:"\f117";
}


.icon--checkmark:before {
	content:"\f118";
}


.icon--classroom:before {
	content:"\f119";
}


.icon--clean:before {
	content:"\f11a";
}


.icon--clock-full:before {
	content:"\f11b";
}


.icon--clock:before {
	content:"\f11c";
}


.icon--close:before {
	content:"\f11d";
}


.icon--comment:before {
	content:"\f11e";
}


.icon--contact:before {
	content:"\f11f";
}


.icon--copy-full:before {
	content:"\f120";
}


.icon--copy:before {
	content:"\f121";
}


.icon--delete-note:before {
	content:"\f122";
}


.icon--document-fold:before {
	content:"\f123";
}


.icon--document:before {
	content:"\f124";
}


.icon--download:before {
	content:"\f125";
}


.icon--edit-pencil:before {
	content:"\f126";
}


.icon--edit:before {
	content:"\f127";
}


.icon--error-disconnect:before {
	content:"\f128";
}


.icon--error-server:before {
	content:"\f129";
}


.icon--euro-circle:before {
	content:"\f12a";
}


.icon--event-academic:before {
	content:"\f12b";
}


.icon--event-circle-fill:before {
	content:"\f12c";
}


.icon--event-circle:before {
	content:"\f12d";
}


.icon--event-extern:before {
	content:"\f12e";
}


.icon--event-personal:before {
	content:"\f12f";
}


.icon--excel:before {
	content:"\f130";
}


.icon--exclaim-full:before {
	content:"\f131";
}


.icon--exclaim:before {
	content:"\f132";
}


.icon--exit:before {
	content:"\f133";
}


.icon--external-link:before {
	content:"\f134";
}


.icon--faq:before {
	content:"\f135";
}


.icon--flag:before {
	content:"\f136";
}


.icon--folder-full:before {
	content:"\f137";
}


.icon--folder:before {
	content:"\f138";
}


.icon--forward:before {
	content:"\f139";
}


.icon--grade:before {
	content:"\f13a";
}


.icon--group:before {
	content:"\f13b";
}


.icon--groups-full:before {
	content:"\f13c";
}


.icon--groups:before {
	content:"\f13d";
}


.icon--heart-full:before {
	content:"\f13e";
}


.icon--heart:before {
	content:"\f13f";
}


.icon--hidden:before {
	content:"\f140";
}


.icon--home:before {
	content:"\f141";
}


.icon--info-full:before {
	content:"\f142";
}


.icon--info-session:before {
	content:"\f143";
}


.icon--info:before {
	content:"\f144";
}


.icon--intrauoc:before {
	content:"\f145";
}


.icon--lab:before {
	content:"\f146";
}


.icon--library-respons:before {
	content:"\f147";
}


.icon--library:before {
	content:"\f148";
}


.icon--list-full:before {
	content:"\f149";
}


.icon--list-simple:before {
	content:"\f14a";
}


.icon--list:before {
	content:"\f14b";
}


.icon--lock-full:before {
	content:"\f14c";
}


.icon--lock:before {
	content:"\f14d";
}


.icon--locked-full:before {
	content:"\f14e";
}


.icon--locked:before {
	content:"\f14f";
}


.icon--mail-full:before {
	content:"\f150";
}


.icon--mail:before {
	content:"\f151";
}


.icon--material-audio-full:before {
	content:"\f152";
}


.icon--material-audio:before {
	content:"\f153";
}


.icon--material-epub-full:before {
	content:"\f154";
}


.icon--material-epub:before {
	content:"\f155";
}


.icon--material-html5-full:before {
	content:"\f156";
}


.icon--material-html5:before {
	content:"\f157";
}


.icon--material-mobipocket-full:before {
	content:"\f158";
}


.icon--material-mobipocket:before {
	content:"\f159";
}


.icon--material-pdf-full:before {
	content:"\f15a";
}


.icon--material-pdf:before {
	content:"\f15b";
}


.icon--material-video-full:before {
	content:"\f15c";
}


.icon--material-video:before {
	content:"\f15d";
}


.icon--material-web-full:before {
	content:"\f15e";
}


.icon--material-web:before {
	content:"\f15f";
}


.icon--message:before {
	content:"\f160";
}


.icon--minus-small:before {
	content:"\f161";
}


.icon--mobile:before {
	content:"\f162";
}


.icon--move:before {
	content:"\f163";
}


.icon--new:before {
	content:"\f164";
}


.icon--news:before {
	content:"\f165";
}


.icon--paperwork:before {
	content:"\f166";
}


.icon--pause:before {
	content:"\f167";
}


.icon--pdf:before {
	content:"\f168";
}


.icon--pencil:before {
	content:"\f169";
}


.icon--phone-white:before {
	content:"\f16a";
}


.icon--phone:before {
	content:"\f16b";
}


.icon--play-fill:before {
	content:"\f16c";
}


.icon--play-simple:before {
	content:"\f16d";
}


.icon--play:before {
	content:"\f16e";
}


.icon--plus-small:before {
	content:"\f16f";
}


.icon--plus-square:before {
	content:"\f170";
}


.icon--plus:before {
	content:"\f171";
}


.icon--pointer-maps-full:before {
	content:"\f172";
}


.icon--pointer-maps:before {
	content:"\f173";
}


.icon--portfolio:before {
	content:"\f174";
}


.icon--ppt:before {
	content:"\f175";
}


.icon--print:before {
	content:"\f176";
}


.icon--profile:before {
	content:"\f177";
}


.icon--question-mark:before {
	content:"\f178";
}


.icon--quote:before {
	content:"\f179";
}


.icon--radio-button-off:before {
	content:"\f17a";
}


.icon--radio-button-on:before {
	content:"\f17b";
}


.icon--refresh:before {
	content:"\f17c";
}


.icon--repository:before {
	content:"\f17d";
}


.icon--respond:before {
	content:"\f17e";
}


.icon--restore:before {
	content:"\f17f";
}


.icon--rss-social-media:before {
	content:"\f180";
}


.icon--rss-white:before {
	content:"\f181";
}


.icon--save-full:before {
	content:"\f182";
}


.icon--save:before {
	content:"\f183";
}


.icon--scroll-to:before {
	content:"\f184";
}


.icon--search-full:before {
	content:"\f185";
}


.icon--search:before {
	content:"\f186";
}


.icon--send-mail:before {
	content:"\f187";
}


.icon--settings:before {
	content:"\f188";
}


.icon--share:before {
	content:"\f189";
}


.icon--show:before {
	content:"\f18a";
}


.icon--smiley:before {
	content:"\f18b";
}


.icon--social-facebook:before {
	content:"\f18c";
}


.icon--social-flickr:before {
	content:"\f18d";
}


.icon--social-instagram:before {
	content:"\f18e";
}


.icon--social-linkedin:before {
	content:"\f18f";
}


.icon--social-media-2:before {
	content:"\f190";
}


.icon--social-media-3:before {
	content:"\f191";
}


.icon--social-spotify:before {
	content:"\f192";
}


.icon--social-twitter-white:before {
	content:"\f193";
}


.icon--social-twitter:before {
	content:"\f194";
}


.icon--social-vimeo:before {
	content:"\f195";
}


.icon--social-whatsapp:before {
	content:"\f196";
}


.icon--social-youtube:before {
	content:"\f197";
}


.icon--speed:before {
	content:"\f198";
}


.icon--stop:before {
	content:"\f199";
}


.icon--subcategory:before {
	content:"\f19a";
}


.icon--subscription:before {
	content:"\f19b";
}


.icon--support:before {
	content:"\f19c";
}


.icon--training-offer:before {
	content:"\f19d";
}


.icon--translation:before {
	content:"\f19e";
}


.icon--trash:before {
	content:"\f19f";
}


.icon--triangle-left:before {
	content:"\f1a0";
}


.icon--triangle-right:before {
	content:"\f1a1";
}


.icon--tutorship:before {
	content:"\f1a2";
}


.icon--user-simple:before {
	content:"\f1a3";
}


.icon--user:before {
	content:"\f1a4";
}


.icon--volume:before {
	content:"\f1a5";
}


.icon--vote:before {
	content:"\f1a6";
}


.icon--word:before {
	content:"\f1a7";
}


.icon--write:before {
	content:"\f1a8";
}


.icon--zhamburguer:before {
	content:"\f1a9";
}

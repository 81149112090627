// Visibility helper mixin
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
  table#{$parent}  { display: table !important; }
  tr#{$parent}     { display: table-row !important; }
  th#{$parent},
  td#{$parent}     { display: table-cell !important; }
}

// Invisibility helper mixin
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}
/* Ruler */

.ruler--bottom {
    border-top: 0;
    border-bottom-width: 4px;
    &.ruler--thin{
        border-bottom-width: 1px;
    }
}

/* Screen Readers */

.sr-only {
    @extend .visually-hidden;
}

p.lead {
    font-size: 1.35rem;
    padding-top: .3rem;
    line-height: 1.25;
}

.text-normal {
    font-weight: normal;
}

.yes-no{
    min-width: 24px;
    display:inline-block;
}


.sync-status{
    .icon{
        top: 1px;
        position: relative;
    }
}

.external-link{
    position: relative;
    text-decoration: none;
    word-break: break-all;
    &:hover, &:focus{
        text-decoration: none;
    }
    .lbl{
        /*@include text-overflow();
        max-width: 95%;*/
        text-decoration: underline;
    }
}


.is-disabled {
    cursor: not-allowed;
    > a {
        color: $grey;
        display: inline-block;
        pointer-events: none;
        text-decoration: none;
    }
}



dl {
    margin: 0 0 .75rem 0;
    dt{
        float: left;
        clear: left;
        text-align: left;
        font-weight: bold;
        width: 50%;  
        padding-right: .25rem;
    }
    dd{
        font-weight: normal;
        margin: 0 0 0 50%;
        padding: 0 0 .25rem 0;
    }
}

.table{
    dl{ 
        margin: -.25rem 0;
        dt{
            padding: .25rem .25rem .25rem 0;
        }
        dd{
            padding: .25rem 0;
        }
    }
}

@include media-up(sm) {
    dl {
        dt{
            width: 40%;  
        }
        dd{
            margin: 0 0 0 40%;
        }
    }
}

@include media-up(md) {
    dl {
        dt{
            width: 30%;  
        }
        dd{
            margin: 0 0 0 30%;
        }
    }
}


@include media-up(lg) {
    dl {
        dt{
            width: 25%;  
        }
        dd{
            margin: 0 0 0 25%;
        }
    }
}

@include media-up(xl) {
    dl {
        dt{
            width: 20%;  
        }
        dd{
            margin: 0 0 0 20%;
        }
    }
}

// Size default icons
$icon-normal-font-size:					1.937rem;
$icon-small-font-size:					.875rem;

$icon-big-default-size:	                4.4rem;
$icon-normal-default-size: 				3.4rem;
$icon-small-default-size:				1.75rem;

// Size/Weight arrow icons
$icon-big-arrows-size:	                2.4375rem;
$icon-normal-arrows-size: 				1.2rem;
$icon-small-arrows-size:				.875rem;
$icon-normal-arrows-weight:				600;
$icon-small-arrows-weight:				400;
$icon-big-arrows-weight:				400;

$icon-side-size-1:		                2.5rem;
$icon-side-size-2:		                1.7rem;

// Size button icons
$icon-big-button-size:	                1.5625rem;
$icon-normal-button-size: 				1.5625rem;
$icon-small-button-size:				1.5625rem;

// Size link icons
$icon-big-link-size:	                1.75rem;
$icon-normal-link-size:					1.75rem;
$icon-small-link-size:					1.75rem;
$icon-big-link-width:	                20px;
$icon-normal-link-width:				20px;
$icon-small-link-width:					20px;

// Size specific icons
$icon-social-font-size:					1.6rem;
$icon-social-margin:					.75rem;
$icon-header-font-size:					5rem;

$icon-play-big-size:					  7rem;
$icon-play-normal-size: 				4.75rem;
$icon-play-small-size:					3.75rem;

$icon-search-big-size:					8rem;
$icon-search-normal-size: 				2.25rem;
$icon-search-small-size:				2.25rem;

$icon-campus-big-size:					4.375rem;
$icon-campus-normal-size:				4.375rem;
$icon-campus-small-size:				4.375rem;

$icon-alert-big-size:					3.187rem;
$icon-alert-small-size:					1.937rem;

$icon-info-big-size:					5.875rem;
$icon-info-small-size:				3.45rem;

$icon-alert-big-size:					5.25rem;
$icon-alert-small-size:					2.8125rem;

$icon-book-big-size:					5.5rem;
$icon-book-small-size:					3.6rem;

$icon-hamburguer-size:					4.5rem;

// Default size icons
$icon-big-size:                         4.9rem;
$icon-small-size:                       1.3rem;
$icon-xsmall-size:                      1.1rem;
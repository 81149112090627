// Brand header light (Campus)

.brand-header-light {
    height: auto;
    line-height: 1.1;

    .header-container {
        max-width: $grid-max-width;
        margin: 0 auto;
        
    }

    .top-logo {
        display: inline-block;
        width: auto;
        margin-right: 5px;
        position: absolute;
        left: 2px;
        top: 0;
        height: 42px;

        img {
            height: 100%;
            width: auto;
        }
    }

    .top-slogan {
        font-family: $font-family-serif;
        font-size: .8rem;
        padding-top: 3px;
        overflow: hidden;
        height: 42px;
        margin: 0 0 0 63px;
    }

    .top-title-wrapper {
        .top-title {
            @include font-size($light-header-title-font-size);

            line-height: 1;
            margin-top: 2px;
            margin-bottom: 4px;
        }
    }

    /*@include media-down(sm) {
        .top-title-wrapper {
            border-color: $brand-primary;
            border-bottom: 0;
            margin: 16px 0;
        }
    }*/

    @include media-up(md) {
        .top-logo {
            height: 68px;
            left: 4px;
        }

        .top-title-wrapper {
            height: 68px;
            margin-top: 0;
            overflow: hidden;
        }

        .top-slogan {
            font-size: 1rem;
            height: 68px;
            margin-left: 100px;
        }
    }


    @include media-up(lg) {
        .top-slogan {
            font-size: 1.1rem;
        }
    }
}

.brand-header-light .top-title {
    font-weight: bold;
}

/* Heading */

.heading {
    min-height: $fixed-heading-margin-mobile;
    padding-top: 10px;
    nav{
        font-size: 1.1rem;
        line-height: 1.1;
        .btnlink{
            font-size: 1.1rem;
            .icon{
                position: relative;
                top: 2px;
            }
        }
    }
}

@include media-up(md) {
    .heading {
        min-height: $fixed-heading-margin;
        padding-top: 25px;
    }
}


.fixed-heading {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: $zindex-fixed-heading;
    background: #fff;
    //padding-top: 10px;
}

/*.no-fixed-heading{
    margin-top: 110px;
}*/

.nav-breadcrumb{
    overflow: hidden;
}

.breadcrumb {
    display: block;
    padding: 14px 0;
    margin: 0;
    font-weight: normal;
    @include text-overflow;
    .breadcrumb-item:not(:last-child):after{
        margin: 0 0.5rem 0 .3rem
    }
    .breadcrumb-item.active:first-child{
        font-weight: normal;
    }
}



.heading-title {
    font-size: 2.25rem;
    @include text-overflow();
}

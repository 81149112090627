// Form group mixin
@mixin form-item-size($padding-y, $padding-x, $font-size, $line-height) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
}

// Form item placeholder mixin
@mixin input-placeholder{
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

/* Rem Units */
$baseline-px: 16px;
@mixin rem($property, $px-values) {
	$baseline-rem: $baseline-px / 1rem;
	#{$property}: $px-values;
	@if type-of($px-values) == "number" {
		#{$property}: $px-values / $baseline-rem; 
	}
	@else {
		$rem-values: unquote("");
		@each $value in $px-values {
			@if $value == 0 or type-of($value) == "string" or type-of($value) == "color" {
				$rem-values: append($rem-values, $value); }
			@else {
				$rem-values: append($rem-values, $value / $baseline-rem); 
			} 
		}
		#{$property}: $rem-values; 
	} 
}
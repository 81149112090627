@mixin transition($property: all, $duration: 0.2s, $delay: 0s) {
    -webkit-transition-property: $property;
    -moz-transition-property: $property;
    -o-transition-property: $property;
    transition-property: $property;
    -webkit-transition-duration: $duration;
    -moz-transition-duration: $duration;
    -o-transition-duration: $duration;
    transition-duration: $duration;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-delay: $delay;
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    transition-delay: $delay;
}

@mixin transform($value: none) {
    -webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;
    transform: $value;
}

@mixin transform-origin($value: center) {
    -webkit-transform-origin: $value;
    -moz-transform-origin: $value;
    -ms-transform-origin: $value;
    transform-origin: $value;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity = $opacity-ie);
}

.nav-options{
    @include font-size($font-size-s);
    .btnlink{
        @include font-size($font-size-s);
    }
    &:not(.only-results){
        .result-total{
            &:after{
                content: '|';
                margin: 0 1rem 0 .85rem;
                display: inline-block;
                font-size: .8rem;
                position: relative;
                top: -.15rem;
                color: $grey-app;
            }
        }
    }
    ol, ul{
        margin: 0;
    }

}

.list--limits{
    display: inline-block;
    li{
        margin-left: .5rem;
    }
}

.list--options{
    .icon{
        position: relative;
        top: 1px;
    }
    .icon--columns{
        top: 2px;
        left: -1px;
    }
    > li{
        padding: 0;
        &:after{
            content: '|';
            margin: 0 1rem 0;
            display: inline-block;
            font-size: .8rem;
            position: relative;
            top: -.15rem;
            color: $grey-app;
        }
        &:last-child:after{
            display: none;
        }
    }
}

.list--dropdown{
    margin: 0;
    padding: .75rem .5rem .25rem;
    li{
        margin: 0;
        padding: .25rem 0;
    }
}


@include media-down(sm) {
    .list--nav {
        li {
            display: block;
            float: none;
            margin: 0;
            padding: 0;
        }

        .btn {
            margin: .5rem 0;
            display: block;
            width: 100%;
        }

        li:first-child {
            .btn {
                margin-top: 0;
            }
        }

        li:last-child {
            .btn {
                margin-bottom: 0;
            }
        }
    }
}

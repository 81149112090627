body {
    background-color: #fff;
    overflow-y: scroll;
}

/*a {
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}*/

.main {
    position: relative;
    margin-bottom: 2.5rem;
    &.with-sticky-header{
        margin-top: $fixed-heading-margin-mobile;
    }
}

@include media-up(md) {
    .main.with-sticky-header{
        margin-top: $fixed-heading-margin;
    }
    
}


/* GEF Grid adjustments */

.container{
    //min-width: $grid-min-width;
    max-width: $grid-max-width;
}



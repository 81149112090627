.fade {
  transition: $transition-fade;

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: $transition-collapse;
}

.appear-enter,
.appear-appear {
  opacity: 0.01;
  //transform: translate(0, 12px);
}

.appear-enter.appear-enter-active,
.appear-appear.appear-appear-active {
  opacity: 1;
  //transform: translate(0, 0);
  transition: opacity 300ms ease-out; //, transform 300ms ease-out;
}

.appear-exit {
  opacity: 1;
  //transform: translate(0, 0);
}

.appear-exit.appear-exit-active {
  opacity: 0.01;
  //transform: translate(0, 12px);
  transition: opacity 300ms ease-in; //, transform 300ms ease-in;
}

.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholdLoader;
  animation-timing-function: linear;
  background-color: #f6f7f8 !important;
  background: linear-gradient(
    to right,
    #fafafa 8%,
    #f4f4f4 38%,
    #fafafa 54%
  ) !important;
  background-size: 900px 450px;
}

@keyframes placeholdLoader {
  0% {
    background-position: -450px 0;
  }

  100% {
    background-position: 450px 0;
  }
}

/*
   Animation example, for spinners
*/

.syncing {
  transform: trasnslate3d(0, 0, 0);
  -moz-animation: spin-sync 1s infinite ease;
  -o-animation: spin-sync 1s infinite ease;
  -webkit-animation: spin-sync 1s infinite ease;
  animation: spin-sync 1s infinite ease;
  display: inline-block;
  @include transform-origin(50% 50%);
}

@keyframes spin-sync {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(-359deg);
    -o-transform: rotate(-359deg);
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}


.spin-clock {
    transform: trasnslate3d(0, 0, 0);
    -moz-animation: spin-clock 60s infinite linear;
    -o-animation: spin-clock 60s infinite linear;
    -webkit-animation: spin-clock 60s infinite linear;
    animation: spin-clock 60s infinite linear;
    display: inline-block;
    @include transform-origin(50% 50%);
  }
  
  @keyframes spin-clock {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
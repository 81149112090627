/**
 * NOTA: viene a reemplazar a gef/components/_icons.scss por conflicto del original con dart sass (not-gef/_icons.scss): 
 * @extend only simple selectors
 * (https://sass-lang.com/documentation/at-rules/extend#disallowed-selectors) 
 */

.icon{
	text-decoration: none;
	font-size: $icon-normal-font-size;
}


h1, h2, h3, h4, h5, h6{
	& .icon{
		font-size: 100%;
	}
}


.icon--before{
	margin-right: .5rem;
}
.icon--after{
	margin-left: .5rem;
}

[class^="icon--social"], [class*=" icon--social"] {
	font-size: $icon-social-font-size;
	&:not(:last-child){
		margin-right: $icon-social-margin;
	}
}

.icon--social{
	font-size: $icon-social-font-size;
	&:not(:last-child){
		margin-right: $icon-social-margin;
	}
}

// Default size icons
.icon--big{
	font-size: $icon-big-size;
}
.icon--small{
	font-size: $icon-small-size;
}
.icon--xsmall{
	font-size: $icon-xsmall-size;
}
// Size default icons
.icon--big-default{
	font-size: $icon-big-default-size;
}
.icon--normal-default{
	font-size: $icon-normal-default-size;
}
.icon--small-default{
	font-size: $icon-small-default-size;
}
// Size/Weight arrow icons
.icon--normal-arrows{
	font-size: $icon-normal-arrows-size;
	font-weight: $icon-normal-arrows-weight;
}
.icon--big-arrows{
	font-size: $icon-big-arrows-size;
	font-weight: $icon-big-arrows-weight;
}
.icon--small-arrows{
	font-size: $icon-small-arrows-size;
	font-weight: $icon-small-arrows-weight;
}

.icon--play{
	&-big, &-center{
			font-size: $icon-play-big-size;
			width: 100%;
			height: 100%;
	}
}
.icon--search{
	&-small{
		font-size: $icon-search-small-size;
	}
	&-big{
		font-size: $icon-search-big-size !important;
	}
	&-normal{
		font-size: $icon-search-normal-size;
	}
}
.icon--campus {
	&-small{
		font-size: $icon-campus-small-size;
	}
	&-big{
		font-size: $icon-campus-big-size;
	}
	&-normal{
		font-size: $icon-campus-normal-size;
	}
}
.icon--header--bottom{
	position: relative;
	font-size: $icon-header-font-size;
}
.icon--header--top{
	position: relative;
	font-size: $icon-header-font-size;
}
.icon--header--left{
	position: relative;
	font-size: $icon-header-font-size;
}
.icon--header--right{
	position: relative;
	font-size: $icon-header-font-size;
}
.icon--info{
	&--small{
		font-size: $icon-info-small-size;
 	}
 	&--big{
 		font-size: $icon-info-big-size;
 	}
 	&--normal{
 		font-size: $icon-info-big-size;
 	}
}
.icon--alert{
	&--small{
		font-size: $icon-alert-small-size;
 	}
 	&--big{
 		font-size: $icon-alert-big-size;
 	}
 	&--normal{
 		font-size: $icon-alert-big-size;
 	}
}
.icon--book--open-registration{
	&--small{
		font-size: $icon-book-small-size;
		line-height: 46px;
		text-indent: -5px;
	}
	&--big{
		font-size: $icon-book-big-size;
		line-height: 71px;
		text-indent: -5px;
	}
	&--normal{
		font-size: $icon-book-big-size;
		line-height: 71px;
		text-indent: -5px;
	}
}
// Icons positions
.icon--pleft {
    position: relative;
    float: left;
    left: -10px;
    margin: auto;
}
.icon--pcenter {
    position: relative;
    left: 0;
    margin: auto;
}
.icon--pright {
    position: relative;
    float: right;
    right: -10px;
    margin: auto;
}
.icon-card-right-top{
	position: absolute;
	top: -10px;
	right: -7px;
}


// Custom
.icon--normal {
    font-size: $icon-normal-size;
}


.icon--columns{
	@extend .icon--zhamburguer;
	position: relative;
	font-size: 1.35rem;
	&.is-on:after{
		content: '';
		position: absolute;
		display: inline-block;
		right: 0;
		top: 0;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background-color: $brand-secondary;
	}
    .lines{
        &:before,
        &:after{
            content: '';
            width:2px;
            height: 90%;
            position: absolute;
            top: 0;
            bottom: 0;
            background-color: $white;
        }
        &:before{
            left: 8px;
        }
        &:after{
            left: 13px;
        }
    }
}
// Typography
//
// Font-face.
$font-url:  '../fonts/';

/*@font-face {
    font-family: 'uoc-sans';
    src:url('#{$font-url}UOCSans-Regular.woff2') format('woff2'),
    url('#{$font-url}UOCSans-Regular.woff') format('woff'),
    url('#{$font-url}UOCSans-Regular.ttf') format('truetype'),
    url('#{$font-url}UOCSans-Regular.svg#svgFontName') format('svg');
    font-weight:400;
}
@font-face {
    font-family: 'uoc-sans';
    src:url('#{$font-url}UOCSans-Italic.woff2') format('woff2'),
    url('#{$font-url}UOCSans-Italic.woff') format('woff'),
    url('#{$font-url}UOCSans-Italic.ttf') format('truetype'),
    url('#{$font-url}UOCSans-Italic.svg#svgFontName') format('svg');
    font-weight:400;
    font-style:italic;
}
@font-face {
    font-family: 'uoc-sans';
    src:url('#{$font-url}UOCSans-Bold.woff2') format('woff2'),
    url('#{$font-url}UOCSans-Bold.woff') format('woff'),
    url('#{$font-url}UOCSans-Bold.ttf') format('truetype'),
    url('#{$font-url}UOCSans-Bold.svg#svgFontName') format('svg');
    font-weight:600;
}
@font-face {
    font-family: 'uoc-serif';
    src:url('#{$font-url}UOCSerif-Regular.woff2') format('woff2'),
    url('#{$font-url}UOCSerif-Regular.woff') format('woff'),
    url('#{$font-url}UOCSerif-Regular.ttf') format('truetype'),
    url('#{$font-url}UOCSerif-Regular.svg#svgFontName') format('svg');
    font-weight:400;
}*/

// Font, line-height, and color for body text, headings, and more.

$font-family-sans:    						uoc-sans, Arial !default;
$font-family-serif:          				uoc-serif, Georgia !default;

$font-family-monospace:     				Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:           				$font-family-sans !default;

$font-family-alternate:                     $font-family-serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.

$font-size-base:             				20 !default;
$line-height-base:           				1.1 !default;
$font-weight-base:           				400 !default;

$font-size-s:           					17 !default;
$font-size-xs:      						14 !default;
$font-size-lg:             					30 !default;

// Titles

$font-size-h1:               				55 !default;
$line-height-h1:			       			.95 !default;
$font-weight-h1:			       			600 !default;
$font-family-h1:						 	$font-family-sans !default;
$color-h1:									inherit !default;
$margin-bottom-h1:					 		($spacer * 2) !default;

$font-size-h2:               				40 !default;
$line-height-h2:			       		    .95 !default;
$font-weight-h2:			      			600 !default;
$font-family-h2:						 	$font-family-sans !default;
$color-h2:									inherit !default;
$margin-bottom-h2:					 	   ($spacer * 2) !default;

$font-size-h3:               				40 !default; //undefined
$line-height-h3:			       			.95 !default;
$font-weight-h3:			       			400 !default;
$font-family-h3:						 	$font-family-sans !default;
$color-h3:									inherit !default;
$margin-bottom-h3:					 		($spacer * 2) !default;

$font-size-h4:               				28 !default; //undefined
$line-height-h4:			       			1 !default;
$font-weight-h4:			       			400 !default;
$font-family-h4:						 	$font-family-sans !default;
$color-h4:									inherit !default;
$margin-bottom-h4:					 		($spacer * 2) !default;

$font-size-h5:               				20 !default; //undefined
$line-height-h5:			       			1.1 !default;
$font-weight-h5:			       			600 !default;
$font-family-h5:						 	$font-family-sans !default;
$color-h5:									inherit !default;
$margin-bottom-h5:					 		($spacer * 2) !default;

$font-size-h6:               				17 !default; //undefined
$line-height-h6:			       			1.1 !default;
$font-weight-h6:			       			600 !default;
$font-family-h6:						 	$font-family-sans !default;
$color-h6:									inherit !default;
$margin-bottom-h6:					 	    ($spacer * 2) !default;

$line-height:                				1.1 !default;

$font-style-italic: 						italic !default;
$text-muted:                  			    $grey !default;

//$abbr-border-color:           		    $gray-light !default;

//$blockquote-small-color:      		    $gray-light !default;
//$blockquote-font-size:        		    ($font-size-base * 1.25) !default;
//$blockquote-border-color:     		    $gray-lighter !default;


$link-color:								$brand-primary !default;
$link-decoration:							underline !default;
$link-hover-color:							$grey-dark;
$link-hover-decoration:						underline;

$link-color-inverse:						$brand-secondary !default;
$link-hover-color-inverse:					$grey;


.alert {
    font-size: 1.15rem;
    padding: .8rem 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;

    p {
        margin-bottom: .5rem;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.alert--info {
    background-color: $brand-secondary-light;
}

.alert--warning {
    background-color: #fbe5a5;
}

.alert-dismissible {
    position: relative;
    padding-right: 4rem;

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: .5rem 1rem;
        font-size: 2rem;
        line-height: 1;
        color: inherit;
        float: right;
    }
}

.alert-appear {
    .alert {
        margin-top: .25rem;
    }
}

// Alert colors

.color-success{
    color: $color-success;
}

.color-warning{
    color: $color-warning;
}

.color-error{
    color: $color-error;
}



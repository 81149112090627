/* Variables */

$font-url:  '/fonts/';
$iconfont-url:  '/iconfonts/';

// Colors
//$brand-secondary-alt: #b2ffff;
$brand-primary-alt: #263F9B;
$brand-secondary-alt: #d5faff;
$brand-warning-alt: #ffefbf;
$validation-bg: #fbe5a5;
$grey-app: #dddddd;

$color-success:  #84eab3; 
$color-warning: #e1c15f;
$color-error:  $red;


// Spacing
$spacer: 8px !default;
$spacer-double: $spacer * 2 !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$border-width: 1px !default;
$border-width-bold: $border-width * 4 !default;
$border-radius: 0 !default;

// Grid
$grid-container-expand-padding: 2px;
$grid-min-width: 768px;
$grid-max-width: 1504px;
$grid-gutter-width-large: 1.5rem;

$fixed-heading-margin: 207px;
$fixed-heading-margin-mobile: 167px;
$fixed-table-heading-position: 207px;
$fixed-table-heading-position-mobile: 167px;

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);


// Boostrap spacers and displays:
$spacer_rem: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer_rem * .25),
    2: ($spacer_rem * .5),
    3: $spacer_rem,
    4: ($spacer_rem * 1.5),
    5: ($spacer_rem * 3)
  ),
  $spacers
);

//$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$displays: none, inline, inline-block, block, flex;

// List spacing
$list-margin-right: $spacer-x / 2;
$list-margin-bottom: $spacer-x * 2;
$list-nested-padding: $spacer-x * 2;
$list-inline-padding: $spacer-x / 2;
$list-inline-separator-padding: $spacer-x * 2;

// Fonts
$font-size-h1: 55;
$font-size-h2: 40;
$font-size-h3: 40;
$font-size-h4: 28;
$font-size-h5: 22;
$font-size-h6: 17;

$font-size-s: 17;
$font-size-xs: 14;
$font-size-lg: 30;

// Forms
$form-font-size: $font-size-s;
$form-item-padding: 0.6rem 1rem;

// Tables
$table-cell-padding: ($spacer * 1.5) $spacer;
$table-cell-padding-groups: ($spacer * 1.5) ($spacer * .75);

// Icons

$icon-small-size: 1.3rem;
$icon-xsmall-size: 1.1rem;
$icon-normal-size: 1.5rem;
$btn-large-icon-size: 1.225rem;

/*$icon-size-normal: 22;
$icon-size-small: 20;
$icon-size-xsmall: 16;
$icon-size-xxsmall: 12;
$icon-size-medium: 26;*/

// Transitions
$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height .35s ease !default;

// Tooltips
$zindex-tooltip: 1070 !default;
$tooltip-font-size: 1rem !default;
$tooltip-max-width: 400px !default;
$tooltip-large-max-width: 560px !default;
$tooltip-color: $brand-primary !default;
$tooltip-bg: $brand-secondary-alt !default;
$tooltip-border-radius: 0 !default;
$tooltip-opacity: 1 !default;
$tooltip-padding-y: 0.3rem !default;
$tooltip-padding-x: 0.7rem !default;
$tooltip-margin: 0 !default;
$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Transitions
$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Zindex
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-submodal-backdrop: 1051 !default;
$zindex-submodal: 1052 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-dropdown: 1000 !default;
$zindex-fixed-heading: 1001 !default;

// Modals

$modal-inner-padding: 1rem !default;
$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 0.5rem !default;
$modal-title-line-height: 1.1 !default;
$modal-content-bg: #fff !default;
$modal-content-border-color: #fff !default;
$modal-content-border-width: 1px !default;
$modal-content-border-radius: 0 !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba(#000, 0.15) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba(#000, 0.15) !default;

$modal-backdrop-bg: #000 !default;
$modal-backdrop-opacity: 0.7 !default;
$modal-header-border-color: #ddd !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1rem !default;

$modal-lg: 920px !default;
$modal-md: 720px !default;
$modal-sm: 460px !default;

$modal-transition: transform .25s ease-out !default;

// Popovers

$popover-max-width: 220px !default;
$popover-border-width: 1px !default;
$popover-border-color: $brand-primary !default;
$popover-border-radius: 0 !default;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($brand-primary, 0.2) !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: 0.5rem !default;
$popover-body-padding-x: 0.7rem !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $brand-primary !default;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                16rem;
$dropdown-padding-x:                0 !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                $font-size-s !default;
$dropdown-color:                    $brand-primary !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             $brand-primary !default;
$dropdown-border-radius:            0 !default;
$dropdown-border-width:             1px !default;
$dropdown-inner-border-radius:      0 !default;
$dropdown-divider-bg:               $grey-light !default;
$dropdown-divider-margin-y:         $spacer / 2 !default;
$dropdown-box-shadow:               0 3px 10px rgba(0,0,0,.1) !default;

$dropdown-link-color:               $brand-primary !default;
$dropdown-link-hover-color:         $grey !default;
$dropdown-link-hover-bg:            $grey-lighter !default;

$dropdown-link-active-color:        $brand-primary !default;
$dropdown-link-active-bg:           $white !default;

$dropdown-link-disabled-color:      $grey-light !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.5rem !default;

$dropdown-header-color:             $brand-primary !default;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x !default;



// Datepicker
$datepicker__background-color: #fff;
$datepicker__border-color: $brand-primary;
$datepicker__highlighted-color: #C3FFDE;
$datepicker__muted-color: #ddd;
$datepicker__selected-color: $brand-primary;
$datepicker__text-color: $brand-primary;
$datepicker__header-color: $brand-primary;
$datepicker__navigation-disabled-color: $datepicker__muted-color;
$datepicker__border-radius: 0;
$datepicker__day-margin: 1px;
$datepicker__font-size:$font-size-base;
$datepicker__font-family: $font-family-base;
$datepicker__item-size: 2.2rem;
$datepicker__margin: .35rem;
$datepicker__navigation-size: 0.5rem;
$datepicker__triangle-size: 8px;
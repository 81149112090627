.loading {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //background-color: rgba(255,255,255,.25)
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.spin {
    position: absolute;
    text-align: center;
    display: inline-block;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;

    &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        margin-left: -30px;
        border-radius: 50%;
        border: 3px solid $grey-light;
        border-top-color: $brand-primary;
        animation: spin .75s linear infinite;
    }

    &.spin--small {
        width: 30px;
        height: 30px;
        margin-left: -15px;
        margin-top: -15px;

        &:before {
            width: 30px;
            height: 30px;
            margin-top: -15px;
            margin-left: -15px;
            border-width: 2px;
        }
    }

    &.spin--icon {
        width: 20px;
        height: 20px;
        margin-left: -10px;
        margin-top: -10px;

        &:before {
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border: 2px solid $brand-primary-alt;
            border-top-color: $white;
        }
    }
}

.btn .spin.spin--icon {
    position: relative;
    top: 4px;
    left: 8px;
    margin-right: 1.2rem;
}

.btnlink{
    .spin.spin--icon{
        position: relative;
        top: 2px;
        left: 10px;
        margin-right: 1.3rem;
        width: 16px;
        height: 16px;
        margin-left: -8px;
        margin-top: -8px;

        &:before {
            width: 16px;
            height: 16px;
            margin-top: -8px;
            margin-left: -8px;
        }
    }
}
